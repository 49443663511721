const Available = () => {
    return (
        <div className="flex justify-center w-full" style={{height: 'calc(100vh-5rem)'}}>
            {/* <iframe 
                src="https://calendar.google.com/calendar/embed?src=1562b2aacc871600a3811dcb29f0dfb5cd2c60b142fa0196c7ed361c91b83db3%40group.calendar.google.com&ctz=Europe%2FWarsaw" 
                style={{border: 0}} 
                width="800" 
                height="600" 
                frameBorder="0" 
                scrolling="no"
            /> */}
            <iframe 
                src="https://calendar.google.com/calendar/embed?height=600&wkst=2&ctz=Europe%2FWarsaw&bgcolor=%23ffffff&showTitle=0&showTz=0&showCalendars=0&showTabs=0&showPrint=0&src=MTU2MmIyYWFjYzg3MTYwMGEzODExZGNiMjlmMGRmYjVjZDJjNjBiMTQyZmEwMTk2YzdlZDM2MWM5MWI4M2RiM0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%23009688"
                style={{borderWidth:0}} 
                width="800" 
                height="600" 
                frameBorder="0" 
                scrolling="no"
            />
        </div>
    )
}

export default Available;