import {Routes, Route, useLocation} from 'react-router-dom';
import Home from './screens/home';
import Header from './components/header';
import Location from './screens/location';
import Bedrooms from './screens/bedrooms';
import Calendar from './screens/calendar';
import Available from './screens/available';
import Contact from './screens/contact';

const Router = () => {
    const location = useLocation();

    return (
        <div className='bg-pr w-full h-screen overflow-x-hidden'>
        <Header/>
        <Routes location={location} key={location.pathname}>
            <Route
                path='/'
                element={<Home/>}
            />
            <Route
                path='/location'
                element={<Location/>}
            />
            <Route
                path='/bedrooms'
                element={<Bedrooms/>}
            />
            <Route
                path='/calendar'
                element={<Calendar/>}
            />
            <Route
                path='/availability'
                element={<Available/>}
            />
            <Route
                path='/contact'
                element={<Contact/>}
            />
        </Routes>
        </div>
    );
};

export default Router;