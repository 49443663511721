import React, { useEffect, useRef } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { longer, nearest, oneDay } from "../content/location";

interface Props extends React.HTMLAttributes<HTMLDivElement>{
    text: string,
    title: string,
    image: string,
}
const LocationCard: React.FC<Props>  = ({text, title, image, ...props}) => {
    return (
        <div className="flex flex-col p-8 shadow-lg radius-s" {...props}>
            <img className="mb-5" src={image}/>
            <p>{text + " "}
                <span className="font-bold">{title}</span>
            </p>
        </div>
    )
};

const Location = () => {
    return <div className="bg-pr w-full flex flex-col items-center justify-center">
        <div className="video-container">
            <iframe className="video" src="https://www.youtube.com/embed/o7-ZtyjLLWc?autoplay=1&loop=1&rel=0&color=white&controls=0&mute=1&playlist=o7-ZtyjLLWc" frameBorder="0" allowFullScreen/>
        </div>
        <div className="content">
            <div className="blank"/>
            <div className="part flex-adapt justify-evenly" style={{minHeight:0}}>
                <div className="distance">
                    <span className="subtitle">30 minut do</span>
                    <div>
                        <span>Piaszczystej plaży</span>
                        <span>Florencji</span>
                        <span>Pizy</span>
                    </div>
                </div>

                <div className="distance">
                    <span className="subtitle">60 minut do</span>
                    <div>
                        <span>Sienny</span>
                        <span>San Gimignano</span>
                        <span>Chianti</span>
                    </div>
                </div>

                <div className="distance">
                    <span className="subtitle">90 minut do</span>
                    <div>
                        <span>Montepulciano</span>
                        <span>Dużego ośrodka narciarskiego</span>
                    </div>
                </div>
            </div>

            <div className="blank"/>
            <div className="part flex flex-col items-center">
                <span className='text-3xl mb-7'>Najbliższa okolica</span>
                <div className="flex flex-row flex-wrap locations-6" style={{columnGap: '10%', rowGap: '2rem', width: '90%'}}>
                    {nearest.map(card => 
                        <LocationCard 
                            style={{flexGrow: 1, flexShrink: 0}}
                            text={card.attraction}
                            title={card.bold}
                            image={'/photos/toskania-miejsca/'+card.url}
                        />
                    )} 
                </div>
            </div>

            <div className="blank"/>
            <div className="part flex flex-col items-center">
                <span className='text-3xl mb-9'>Jednodniowe wycieczki</span>
                <div className="flex flex-row flex-wrap locations-4" style={{columnGap: '10%', rowGap: '2rem'}}>
                    {oneDay.map(card => 
                        <LocationCard 
                            style={{flexGrow: 1, flexShrink: 0}}
                            text={card.attraction}
                            title={card.bold}
                            image={'/photos/toskania-miejsca/'+card.url}
                        />
                    )} 
                </div>
            </div>

            <div className="blank"/>
            <div className="part flex flex-col items-center">
                <span className='text-3xl mb-9'>Dłuższe wypady</span>
                <div className="flex flex-row flex-wrap locations-4" style={{columnGap: '10%', rowGap: '2rem'}}>
                    {longer.map(card => 
                        <LocationCard 
                            style={{flexGrow: 1, flexShrink: 0}}
                            text={card.attraction}
                            title={card.bold}
                            image={'/photos/toskania-miejsca/'+card.url}
                        />
                    )} 
                </div>
            </div>
        </div>
    </div>  
};

export default Location;