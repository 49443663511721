import { useInView } from "framer-motion";
import { HTMLProps, ReactNode, useEffect, useRef } from "react";

interface Props extends HTMLProps<HTMLParagraphElement>{
    children: ReactNode, d?: number, style?: React.CSSProperties, className?: string,
    vis?: boolean, setVis?: (a: boolean) => void,
};

const AText = ({ children, d, style, className, vis, setVis,...props }:Props)=>{
    const ref = useRef<HTMLParagraphElement>(null);
    const isInView = useInView(ref, { once: true });

    useEffect(() => {
        if(isInView && setVis) setVis(true);
    }, [isInView]);

    return (
        <p
            ref={ref}
            className={"styled-text font-1 animated-font-size " + className}
            style={{
                ...style,
                transform: (isInView || vis) ? "none" : "translateX(-200px)",
                opacity: (isInView || vis) ? 1 : 0,
                transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1)",
                transitionDelay: (d || "0.3") + "s",
                lineHeight: 'calc(1ex / 0.32)',
                letterSpacing: 2,
            }}
            {...props}
        >
            {children}
        </p>
    );
};

export default AText;