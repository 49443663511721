export const nearest = [
    {
        "attraction": "Baseny termalne",
        "bold": "Montecartini Terme",
        "url": "Montecatini-terme.jpg",
    },
    {
        "attraction": "Muzeum Leonardo da Vinci i przepiękne winnice w okolicach",
        "bold": "Vinci",
        "url": "Vinci.jpg"
    },
    {
        "attraction": "Katedra św, Zenona, Piazza Del Duomo i wyścigi konne w",
        "bold": "Pistoi",
        "url": "Pistoia.jpg"
    },
    {
        "attraction": "Park Pinokia w",
        "bold": "Collodi",
        "url": "Collodi.jpg"
    },
    {
        "attraction": "Kolejka Funicolare i uroczy ryneczek z włoskimi knajpkami w",
        "bold": "Montecatili Alto",
        "url": "Montecatini-alto.jpg"
    },
    {
        "attraction": "Rodzinne miasto kompozytora",
        "bold": "Giacomo Pucciniego Lukka",
        "url": "Lucca.jpg"
    }
];

export const oneDay = [
    {
        "attraction": "Krzywa wieża, Pole cudów  i prześliczne uliczki wzdłuż rzeki w",
        "bold": "Pizie",
        "url": "Pisa.jpg",
    },
    {
        "attraction": "Degustacje w winnicach rejonu",
        "bold": "Chianti",
        "url": "Chianti.jpg",
    },
    {
        "attraction": "Średniowieczne wieże na rynku",
        "bold": "San Gimignano",
        "url": "San-Gimignano.jpg",
    },
    {
        "attraction": "Katedra Matki Boskiej Kwietnej,, Dzwonnica Giotta, Galeria Uffizi i wiele innych atrakcji we",
        "bold": "Florencji",
        "url": "Florencja.jpg",
    }
];

export const longer = [
    {
        "attraction": "Nurkowanie, plaże, trekking i piękne widoki na",
        "bold": "Elbie",
        "url": "Elba.jpg"
    },
    {
        "attraction": "Bardzo bogate zabytki starożytności i średniowiecza (kościoły, bazyliki, Koloseum, pałace, akwedukty, fontanny i wiele innych budowli),",
        "bold": "Rzym i Watykan",
        "url": "Rome.jpg"
    },
    {
        "attraction": "Piękne plaże  rozłożone w półkolistych zatokach, pełnych białego piasku i przezroczystej wody na",
        "bold": "Korsyce",
        "url":"Corsica.jpg"
    },
    {
        "attraction": "7000 warownych wież obserwacyjnych, nurkowanie, kajaki w uroczych zatokach na",
        "bold": "Sardynii",
        "url": "Sardynia.jpg"
    }
]