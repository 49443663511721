export const months = [
    {
        name: 'Styczeń',
        tags: [0, 1, 2, 3, 4],
        text: [],
    },
    {
        name: 'Luty',
        tags: [0, 1, 2, 3, 4, 5],
        text: [],
    },
    {
        name: 'Marzec',
        tags: [0, 1, 2, 3, 4, 5, 6],
        text: [
            'LUCCA - w jeden z weekendów marca, odbywają się bardzo popularne Międzynarodowe Targi Komików. Artyści, kolekcjonerzy oraz wystawcy z całego świata.'
        ]
    },
    {
        name: 'Kwiecień',
        tags: [0, 1, 2, 4, 5, 6, 7]
    },
    {
        name: 'Maj',
        tags: [0, 1, 2, 4, 5, 6, 7, 8, 9, 10, 11],
        text: [
            'CANTINE APERTE - otwarte piwnice - odbywa się zawsze w ostatnią niedzielę maja. Jak Toskania długa i szeroka, winiarnie otwierają swoje podwoje i piwnice dla wszystkich chętnych. Oferują darmową degustację win oraz lokalnych przysmaków.'
        ]
    },
    {
        name: 'Czerwiec',
        tags: [0, 1, 2, 4, 5, 6, 7, 8, 9, 10, 11],
        text: [
            `PIZA - LUMINARIA DI SAN RANIERI (16 czerwca)\nW noc zwaną "Luminaria" można zobaczyć ponad 50000 zapalonych świec i pochodni oświetlających budynki miasta po obu stronach rzeki Arno.`,
            `PIZA - GIOCO DEL PONTE (ostatnia niedziela czerwca)\nZawody toczone między mieszkańcami różnych dzielnic miasta. Średniowieczne stroje, parady jeźdźców konnych i 12 zespołów 20-osobowych próbujących przepchnąć 7-tonowy wózek z jednego końca mostu na drugi. Emocje i zaciekła walka gwarantowane.`,
            `FLORENCJA - CALCIO STORICO\nŚw. Jan Chrzciciel jest patronem Florencji, zaś w dniu 24 czerwca, w dniu Św. Jana na Piazzale Michelangelo odbywa się wspaniały pokaz sztucznych ogni. W następującą po tym dniu Niedzielę odbywa się finałowy mecz "antycznego futbolu", czyli mieszaniny futbolu, rugby i wrestlingu, gdzie nie ma tu żadnych reguł. Zwycięski zespół (4-osobowa reprezentacja jednej z dzielnic miasta) dostaje do zjedzenia cielę. Ma to miejsce w czasie następującego po "meczu" ulicznego party.`,
            `AREZZO - ostatnia niedziela czerwca\nJeszcze więcej średniowiecznych strojów i zwyczajów niż w Pizie. Zabicie Saracena, turniej rycerski, marsze i parady w strojach z epoki.`
        ]
    },
    {
        name: 'Lipiec',
        tags: [0, 1, 2, 4, 5, 6, 7, 8, 9, 10, 11],
        text: [
            `SIENA - PIERWSZA EDYCJA PALIO (2 lipca)\nSławny na całym świecie, doroczny wyścig zwany Palio odbywa się dwukrotnie: 2 lipca i 16 sierpnia. Jest to wyścig jadących na oklep jeźdźców reprezentujących poszczególne kwartały miasta wokół głównego rynku, wszystko dla uczczenia Dziewicy Maryi. W Sienie wydzielono 17 rejonów, z których 10 bierze udział w zawodach. Nic nie jest tak ważne jak zwycięstwo. Przyjechać na drugim miejscu to tragedia. Stąd każdy uczestnik jest gotowy do wściekłej walki, musi wyeliminować przeciwników. Godna takiej walki jest zewnętrzna oprawa zawodów. Na główny plac , Piazza del Campo, może wejść do 25000 widzów. Ale żeby obejrzeć dokładnie parady, kostiumy, flagi i sam wyścig, należy przyjść wcześnie. Główny wyścig rozpoczyna się ok. 19.30, a przygotowania zaczynają się kilka dni wcześniej.`,
            `FESTIWAL PUCCINIEGO - lipiec i sierpień\nFestiwal Pucinniego na wolnym powietrzu (Teatro all'aperto) pdbywa się w Torre del Lago koło Viareggio. Początek występów ustalono na wczesny wieczór. Całość odbywa się na brzegu Jeziora Massaciuccoli.`,
            `LUCCA - LETNI FESTIWAL\nSeria koncertów na wolnym powietrzu ma miejsce głównie na Piazza Napoleone. Wydarzenie to uzyskało już rozgłos i przyciąga naprawdę znanych artystów, odbywających letnie tournees w Europie.`,
            `LUCCA - trzecia niedziala lipca\nFestiwal San Paolino, to okazja do obejrzenia średniowiecznych strojów, parad z pochodniami, zawodów kuszniczych. Odbywa się w okolicy San Paolino w Lucca.`
        ]
    },
    {
        name: 'Sierpień',
        tags: [0, 1, 2, 3, 4, 5, 6],
        text: [
            `FESTIWAL WINA VINARIA - koniec sierpnia i początek września\nTen odbywający się w małej miejscowości Montecarlo w okolicach Lucca festiwal win rozrasta się z roku na rok. Lokalni producenci prezentują swoje wina każdemu chętnemu. To jedyna w rodzaju okazja do darmowej, nieograniczonej degustacji.`
        ]
    },
    {
        name: 'Wrzesień',
        tags: [0, 4, 6, 2, 7],
        text: [
            `LUCCA - Miasto zamienia się w miejsce, gdzie czas cofnął się do średniowiecza. Są tu koncerty, targi, pokazy, wystawy, procesje. Punktem kulminacyjnym zaś jest Luminaria di Santa Croce w noc 13 września. Centrum miasta jest wtedy oświetlone tylko pochodniami i świecami, jednocześnie odbywa się parada strojów z epoki średniowiecza, grana jest muzyka, pokazywane są słynne ewolucje z flagami. Procesja zaczyna się przy kościele San Frediano (o 20.00) i posuwa się w kierunku katedry San Martino.`,
            `AREZZO - pierwsza niedziela września\nJeszcze więcej średniowiecznych strojów i zwyczajów. Turniej rycerski, marsze i parady w strojach z epoki. Powtórka imprezy z czerwca`,
            `FLORENCJA - FESTA DELLA RIFICOLONA (7 i 8 września)\nTen festiwal latarń, jest jednym z najstarszych we Florencji. Początki sięgają najważniejszych targów rolniczych, które odbywały się na Piazza SS Annunziata. Rolnicy wraz z rodzinami wstawali bardzo wcześnie rano, ubierali swe najlepsze, niedzielne ubrania i wędrowali do Florencji, oświetlając drogę świecami trzymanymi w papierowych latarniach. Wyrafinowani smakiem florentczycy robili sobie żarty z tego niedzielnego "strojenia" się wieśniaków. Ich dzieci przygotowywały kolorowo pomalowane latarenki i przyłączały się do takiego pochodu gwiżdżąc i pokrzykując żartobliwie. Obecnie, w tym dniu, dzieci florenckie biegają po ulicach z latarenkami (rificolone). Wszyscy zaś bawią się na ulicznych imprezach, w teatrach lub idą na ogromne targowisko zorganizowane tego dnia na placu.`
        ]
    },
    {
        name: 'Październik',
        tags: [0, 1, 2, 3, 4, 5],
        text: []
    },
    {
        name: 'Listopad',
        tags: [0, 1, 2, 3, 4, 5],
        text: []
    },
    {
        name: 'Grudzień',
        tags: [0, 1, 2, 3, 4, 5],
        text: []
    },
];

export const icons = [
    'guidance:sauna',
    'guidance:massage',
    'iconoir:trekking',
    'la:skiing',
    'guidance:camera',
    'mdi:quad',
    'mdi:bike',
    'mdi:basketball-hoop-outline',
    'la:swimmer',
    'mdi:grill-outline',
    'la:wine-glass',
    'game-icons:tree-swing',
    'mdi:fruit-grapes-outline',
    'fluent-emoji-high-contrast:olive',
];