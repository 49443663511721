import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useEffect, useRef, useState } from "react";
import AText from "../components/animatedText";
import { Photos } from "../content/images";

const slideTimeout = 4 * 1000;

const currentMs = () => new Date().getTime();
const n = (n: number) => (n + 1) % 69;

const Home = () => {
    const carousel = useRef<Carousel>(null);
    const [current, setCurrent] = useState<number>(0);
    const [vis, setVis] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<number[]>([]);
    const timeOut = useRef<NodeJS.Timeout | null>(null);
    const [lastChange, setLastChange] = useState<number>(currentMs());

    const clearTimer = () => {
        if(timeOut.current) clearTimeout(timeOut.current);
        timeOut.current = null;
    }

    const onLoaded = (index: number) => {
        // console.log('loaded: ', index);
        setLoaded(l => [...l, index]);
        if(index == current + 1){
            clearTimer();
            timeOut.current = setTimeout(autoNext, slideTimeout - (currentMs()-lastChange));
        }
    };

    useEffect(() => {
        if(carousel.current){
            setLastChange(currentMs());
            carousel.current.moveTo(current);
        }

        if(loaded.includes(n(current))){
            clearTimer();
            timeOut.current = setTimeout(autoNext, slideTimeout);
        }
    }, [current]);

    const autoNext = () => {
        setCurrent(x => {
            return n(x)
        });
    };

    useEffect(() => {
        return clearTimer;
    }, []);

    return <div className="bg-pr w-full flex flex-col items-center justify-center">
        <div className="carousel-container">
            <Carousel
                showThumbs={false}
                className="carousel"
                transitionTime={1000}
                dynamicHeight
                swipeable
                infiniteLoop
                showIndicators={false}
                ref={carousel}
                onChange={index => {
                    setCurrent(index);
                    setLastChange(currentMs);
                }}
            >
                {Array.from(Array(69).keys()).map((img, index) => 
                    <img
                        className="image" 
                        src={"/photos/toskania/image_" + (img+1) + '.jpg'}
                        onLoad={() => onLoaded(index)}
                    />
                )}
            </Carousel>
        </div>
        <div className="content main">
            {/* <div className="blank"/> */}
            <div className="part main">
                <p>Lokalizacja</p>
                <AText>
                    <u>
                        {`Willa posiada fantastyczną lokalizację pozwalającą na sprawny dojazd do perełek Toskanii`}
                    </u>
                    {` - Pizy, Florencji, Lukki czy Sieny. 30-40 minut zajmuje dojazd do piaszczystej plaży przy której znajduje się promenada z restauracjami oferującymi świeżo złowione owoce morza. Wokół nieruchomości znajduje się wiele tras trekkingowych. W okresie zimowym w ciągu 90 minut można dojechać do kurortu narciarskiego oferującego 80 km tras.Więcej informacji znajduje się w zakładce Lokalizacja`}
                </AText>
            </div>

            <div className="blank"/>
            <div className="part main">
                <p>Dom</p>
                <AText>
                    {`Całość do indywidualnego użytku co gwarantuje prywatność. Ponad 300m\u00B2 powierzchni. Na parterze kuchnia, łazienka i dwa salony z dużym kominkiem. Na piętrze 6 sypialni z 3 łazienkami w wygodnym układzie - trzy apartamenty z dwoma sypialniami i łazienką. Budynek z kamienia w toskańskim stylu. Basen z automatycznym robotem czyszczącym o powierzchni 11m x 4m i głębokości 160 cm. Teren o powierzchni 1,7ha z winnicą i gajem oliwnym. Kilka altanek pod którymi moża smakować toskańskie specjały na świeżym powietrzu.`}
                </AText>
            </div>

            <div className="blank"/>
            <div className="part main">
                <p>Atrakcje</p>
                <div className="flex flex-adapt gap-x-10 gap-y-2 flex-wrap" style={{alignItems: 'start'}}>
                    {[`własny kort tenisowy`,
                        `własny basen`,
                        `rowery`,
                        `tenis stołowy`,
                        `rzutki`,
                        `koszykówka`,
                        `trampolina`,
                        `bramki do piłki wodnej`,
                        `huśtawki`,
                        `grill gazowy`,
                        `piec do pizzy`,
                        `maszyna do chleba`,
                        `trekking`,
                        `ps3`
                    ].map((text, index) => 
                        <AText key={index} vis={vis} setVis={setVis} d={(1+index)/10}>{text}</AText>
                    )}
                </div>
            </div>
        </div>
    </div>  
};

export default Home;